import React from 'react';
/* import logo from './logo.svg';
import { Counter } from './features/counter/Counter'; */
import './App.css';
import Main from './layouts/Main';

function App() {
  return (
    <>
      <Main />
    </>
  )
}

export default App;
