import React, { useState, useEffect, useRef } from 'react'

const pdfjs = await import('pdfjs-dist')
pdfjs.GlobalWorkerOptions.workerSrc = './worker/pdf.worker.min.mjs'

const PdfViewer = (props) => {

    const {appState, setAppState, pdfName } = props

    const [document,setDocument] = useState(null)
    const [currentPage,setCurrentPage] = useState(41)
    const [numberOfPages,setNumberOfPages] = useState(0)
    const [currentPageObject,setCurrentPageObject] = useState(null)
    const [curZoomFactor,setCurZoomFactor] = useState(100)

    const canvasRef = useRef()
    const ctrRef = useRef()

    useEffect(()=>{
      const loadLib = async () => {
        const loadingTask = pdfjs.getDocument(pdfName)
        return await loadingTask.promise
      }
      if(!document){
        loadLib().then((document)=>{
          setDocument(document)
          setNumberOfPages(document._pdfInfo.numPages)
        })
      }
    },[document, pdfName])

    useEffect(()=>{
      if(document && currentPage){
        const getPage = async () => {
          return await document.getPage(currentPage)
        }
        getPage().then((page) => {
          setCurrentPageObject(page)
        })
        
    }
    },[document,currentPage])

    useEffect(()=>{

      if(currentPageObject && currentPageObject._pageIndex && appState.status !=='render' && appState.status !=='ready'){
        setAppState({status: 'render'})
        console.log(currentPageObject.view)
        let pagewidth=currentPageObject.view[2]
        console.log('pw: '+pagewidth)
				let pageheight=currentPageObject.view[3]
        let scale=canvasRef.current.clientWidth/pagewidth
        console.log('canvas width: '+canvasRef.current.clientWidth)
        console.log('scale: '+scale)
        let scalez=scale*(curZoomFactor/100)
        let viewport = currentPageObject.getViewport({ scale: scalez })

        canvasRef.current.width = pagewidth*scalez;
        canvasRef.current.height = pageheight*(scalez);

        console.log(canvasRef.current.width)
        console.log(canvasRef.current.height)

					//this.txtctr.style.height=(viewport.height)+'px';
				/* ctrRef.current.style.height=(viewport.height)+'px';
				ctrRef.current.style.width=(viewport.width)+'px'; */

        /* canvasRef.current.width = 500
        canvasRef.current.height = 800 */
        let ctx = canvasRef.current.getContext("2d")
        const renderPage = async () => {
          let renderjob = currentPageObject.render({canvasContext: ctx, viewport: viewport, intent:'display', annotationMode: pdfjs.AnnotationMode.ENABLE, background:'#cccccc'})
          await renderjob.promise
        }
        renderPage().then(
          ()=>{
            setAppState({status: 'ready'})
          }
        )
      }
    },[currentPageObject, setAppState, appState, canvasRef, curZoomFactor])


  return (
    <>
        <div style={{width:'calc(100% - 160px)'}} className={appState.status === 'ready' ? '' : ''}>
          
          <div style={{width:'100%'}}>
            <div className='mypdf_ctr' ref={ctrRef}>
              <canvas id="mypdf_viewer" ref={canvasRef} >
              </canvas>
              </div>
          </div>

        </div>
    </>
  )
}

export default PdfViewer
