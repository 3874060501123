import React from 'react'

const DownBar = (props) => {

    const {appState} = props

  return (
    <>
        { appState.status === 'ready' && <div className='text-center border border-dark  py-2'><p>Barra sottostante</p></div>}
    </>
  )
}

export default DownBar
