import React from 'react'

const TopBar = (props) => {

    const {appState} = props

  return (
    <>
        { appState.status === 'ready' && <div className='wv-100 shadow topbar'>
          <div className='row'>
            <div className='col-6'>
              <button className='btn btn-light btn-topbar-left p-0'>
                <img src='icons/topbar_blocks_icon.png' alt='Home'></img>
                <span className='button-span me-2'>Home</span>
              </button>
            </div>
            <div className='col-6'>
              <div className='float-end me-2'>

                <button className='btn btn-light btn-topbar-right p-0'>
                  <img src='icons/topbar_search_icon.png' alt='Search' title='Search'></img>
                </button>
                <button className='btn btn-light btn-topbar-right p-0'>
                  <img src='icons/topbar_addnote_icon.png' alt='Aggiungi nota' title='Aggiungi nota'></img>
                </button>
                <button className='btn btn-light btn-topbar-right p-0'>
                  <img src='icons/topbar_phones_icon.png' alt='Ascolta' title='Ascolta'></img>
                </button>
                <button className='btn btn-light btn-topbar-right p-0' onClick={()=>{return false}} style={{cursor: 'default'}}>
                  <img src='icons/topbar_divider.png' alt=''></img>
                </button>
                <button className='btn btn-light btn-topbar-right p-0'>
                  <img src='icons/topbar_font_icon.png' alt='Carattere' title='Carattere'></img>
                </button>
                <button className='btn btn-light btn-topbar-right p-0'>
                  <img src='icons/topbar_points_icon.png' alt='Menu' title='Menu'></img>
                </button>

              </div>

            </div>
          </div>
        </div>}
    </>
  )
}

export default TopBar
