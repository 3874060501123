import React, { useState } from 'react'
import TopBar from '../components/TopBar'
import LeftSidebar from '../components/LeftSidebar'
import PdfViewer from '../components/PdfViewer'
import RightSidebar from '../components/RightSidebar'
import DownBar from '../components/DownBar'

const Main = () => {

    const [appState,setAppState] = useState({
        status: 'loading'
    })

    const pdfName = './mybook.pdf' //'http://localhost/mybook.pdf' 

  return (
    <div className='main-container vh-100'>
        <TopBar appState={appState} />
        <div className='central-widebody'>
          <LeftSidebar appState={appState} />
          <PdfViewer appState={appState} setAppState={setAppState} pdfName={pdfName}/>
          <RightSidebar appState={appState} />
        </div>
        <DownBar appState={appState} />
    </div>
  )
}

export default Main
