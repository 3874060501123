import React from 'react'

const LeftSidebar = (props) => {

    const {appState} = props
    
  return (
    <>
        { appState.status === 'ready' && <div className='bg-light pt-3' style={{width: '90px'}}>

        <button className='btn btn-light btn-topbar-left p-0'>
                <img src='icons/leftsidebar_index_icon.png' alt='Home'></img>
              </button>

          </div>}
    </>
  )
}

export default LeftSidebar
