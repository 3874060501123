import React from 'react'

const RightSidebar = (props) => {

const {appState} = props

  return (
    <>
        { appState.status === 'ready' && <div className='bg-light' style={{width: '90px'}}>
          </div>}
    </>
  )
}

export default RightSidebar
